import React, { useContext } from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { breakpointContext } from './footer'

export type FooterLinksType = {
  text: string
  url: string
  target: string
}

export type FooterBottomProps = {
    copyrightText?: string[]
    rightLinks?: FooterLinksType[]
    leftLinks?: FooterLinksType[]
    acknowledgement?: string
} & React.ComponentProps<typeof FooterBottomWrapper>

export const FooterBottomWrapper = styled(Grid)`
  background-color: ${props => props.theme.palette.common.white};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(12)};
    padding: 0 ${props => props.theme.typography.pxToRem(80)};
  }
`

export const FooterMid = styled(Grid)`
  background-color: ${props => props.theme.palette.common.white};
  height: 100%;
  padding-top: ${props => props.theme.typography.pxToRem(52)};
  padding-bottom: ${props => props.theme.typography.pxToRem(29)};
  padding-left: ${props => props.theme.typography.pxToRem(10)};
  padding-right: ${props => props.theme.typography.pxToRem(10)};
`

export const FooterAcknowledgeDiv = styled(Grid)`
  background-color: #f2f2f2;
  color: ${props => props.theme.palette.common.white};
  height: 100%;
`

const CopyRightParagraph = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(20)};
  }
`

export const CopyRight = styled(Typography)`
  color: ${props => props.theme.palette.primary.dark};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  line-height: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
  }
`

export const Acknowledgement = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(12)};
  line-height: ${props => props.theme.typography.pxToRem(22)};
  margin-top: ${props => props.theme.typography.pxToRem(10)};
  margin-bottom: ${props => props.theme.typography.pxToRem(10)};
  padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
    line-height: ${props => props.theme.typography.pxToRem(30)};
  }
`

const FooterLink = styled.a`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  text-align: left;
  text-decoration: underline;
  line-height: ${props => props.theme.typography.pxToRem(18)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }

  &::before {
    content: "";
  }
`

export const LinkList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
`

const StyledFooterListWrapper = styled(Grid)`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  ${props => props.theme.breakpoints.up('md')} {
    justify-content: space-around;
  }
`

const FooterListContainer = styled(Grid)`
  width: 100%;
`

export const UnstyledLink = styled.li`
  margin-bottom: ${props => props.theme.typography.pxToRem(15)};
  line-height: ${props => props.theme.typography.pxToRem(24)};

  &::before {
    content: "";
    padding: 0;
  }
`

export const FooterBottom: React.FC<FooterBottomProps> = ({ acknowledgement, copyrightText, leftLinks, rightLinks }) => {
  const { mobile } = useContext(breakpointContext)
  
  return (
    <FooterBottomWrapper 
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        xs={10}
        sm={12}
        md={10}
      >
        <FooterMid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction="row"
        >
          <CopyRightParagraph
            item
            md={mobile ? 6 : 12}
          >
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start">
            </Grid>
            {copyrightText && copyrightText.map((text, key) => <CopyRight key={key}>{text}</CopyRight>)}
          </CopyRightParagraph>
          <FooterListContainer
            item
            md={mobile ? 6 : 12}
          >
            <StyledFooterListWrapper
              container
            >
              <Grid
                item
                xs={6}
                sm={5}
              >
                <Grid
                  direction="row"
                  container
                >
                  <LinkList>
                    {leftLinks && leftLinks.map((link, key) =><UnstyledLink key={key}>
                      <FooterLink href={link.url} target={link.target}>{link.text}</FooterLink>
                    </UnstyledLink>)}
                  </LinkList>
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                sm={5}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <LinkList>
                    {rightLinks && rightLinks.map((link, key) =><UnstyledLink key={key}>
                      <FooterLink href={link.url} target={link.target}>{link.text}</FooterLink>
                    </UnstyledLink>)}
                  </LinkList>
                </Grid>
              </Grid>

            </StyledFooterListWrapper>
          </FooterListContainer>

        </FooterMid>
      </Grid>
      <Grid
        item
        md={10}
      >
        <FooterAcknowledgeDiv 
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
        >
          <Acknowledgement>
            {acknowledgement}
          </Acknowledgement>

        </FooterAcknowledgeDiv>
      </Grid>

    </FooterBottomWrapper>
  )
}
